import "./styles.css";
import React from "react";

import { HttpRequest } from "../../service/HttpRequest";
import { useGlobalContext } from "../../service/GlobalContext";

import Spinner from "../../component/spinner";
// import InsaneCollapseContent from "../../component/insane-collapse-content";
import usePagination from './pagination';

import ConfirmDialog from './dialog-confirm';

import iconNext from "../../assets/icons/arrow_forward_white.png";
import iconBack from "../../assets/icons/arrow_back_white.png";
import iconFirst from "../../assets/icons/first_page_white.png";
import iconLast from "../../assets/icons/last_page_white.png";

import iconEdit from "../../assets/icons/edit_white.png";
import iconDelete from "../../assets/icons/delete_white.png";

function App() {
  const [globalState, setGlobalState] = useGlobalContext();
  const pagination = usePagination();


  const [loading, setLoading] = React.useState(false);
  const [STATE_PAGE, SET_STATE_PAGE] = React.useState("userlist");


  const [USERS, SET_USERS] = React.useState([]);
  const [error_message, set_error_message] = React.useState([]);

  const [formData, setFormData] = React.useState({
    username: '',
    password: '',
    level: '',
    province_id: '',
  });

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setFormData({
      username: '',
      password: '',
      level: '',
      province_id: '',
    });
  };

  // ฟังก์ชันจัดการการเปลี่ยนแปลงของ input fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const getUserList = async (signal) => {
    setLoading(true);

    const data = await HttpRequest("/api/getuserlist", {
      index: pagination.index,
      count: pagination.count
    }, { signal });

    if (data.result === "OK") {
      SET_USERS(data.userlist.users);
      pagination.setTotalCount(data.userlist.totaluser);
    }

    setLoading(false);
  };

  function onCancelAdd() {
    SET_STATE_PAGE("userlist");
  }

  async function onSaveAdd() {

    setLoading(true);
    console.log("formData : ", formData);

    if (validate()) {
      await HttpRequest("/api/updateuser", formData).then((data) => {
        console.log("HttpRequest onSaveAdd :", data);
        if (data.result === "OK") {
          SET_STATE_PAGE("userlist");
          setFormData({
            username: '',
            password: '',
            level: '',
            province_id: '',
          });
          getUserList();
        } else {
          set_error_message([data.errormessage]);
        }
      });

    } else {
      console.log('Validation errors:', error_message);
    }

    setLoading(false);
  }

  async function onSaveUpdate() {

    setLoading(true);
    console.log("formData : ", formData);

    if (validate()) {
      await HttpRequest("/api/updateuser", formData).then((data) => {
        console.log("HttpRequest onSaveUpdate :", data);
        if (data.result === "OK") {
          SET_STATE_PAGE("userlist");
          setFormData({
            username: '',
            password: '',
            level: '',
            province_id: '',
          });
          getUserList();
        } else {
          set_error_message([data.errormessage]);
        }
      });

    } else {
      console.log('Validation errors:', error_message);
    }

    setLoading(false);
  }

  function onSelectEdit(user) {
    setFormData(user);
    SET_STATE_PAGE("edituser");
  }


  const onConfirmDeleteUser = (user) => {
    console.log("Confirmed!");
    setFormData(user);
    handleOpenDialog();
  };

  async function onDeleteUser(user) {
    console.log("onDeleteUser :", user);

    await HttpRequest("/api/removeuser", {
      "userid": formData.userid
    }).then((data) => {
      console.log("HttpRequest onDeleteUser :", data);
      if (data.result === "OK") {

        setFormData({
          username: '',
          password: '',
          level: '',
          province_id: '',
        });

        getUserList();
        setOpenDialog(false);
      }
    });
  }

  const validate = () => {
    const newErrors = [];

    if (!formData.username) {
      newErrors.push('Please enter Username');
    } else if (formData.username.length < 5) {
      newErrors.push('Username must be at least 5 characters.');
    }

    if (!formData.password) {
      newErrors.push('Please enter Password');
    } else if (formData.password.length < 8) {
      newErrors.push('Password must be at least 8 characters.');
    }

    if (!formData.level) {
      newErrors.push('Please select level.');
    }

    if (!formData.province_id) {
      newErrors.push('Please select Province.');
    }

    set_error_message(newErrors);

    return Object.keys(newErrors).length === 0; // คืนค่า true ถ้าไม่มี error
  };


  React.useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      // getEnum(); // Uncomment if you need to call this once at the start
      await getUserList(signal);
    };

    fetchData();

    return () => {
      controller.abort();
    };
  }, [pagination.index, pagination.count]);


  return (
    <>
      {loading ? (
        <div
          className="main-content"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <div className="page-content bg-image">
            <Spinner></Spinner>
            <div style={{ color: "#FFF" }}>กำลังโหลด</div>
          </div>
        </div>
      ) : (
        <div className="main-content">
          <div className="page-content bg-image">
            {
              (STATE_PAGE === "userlist") ? (
                <div className="content-user-management">
                  <div className="content-title">
                    <div className="t">User list</div>
                    <div style={{ flexGrow: 1 }}></div>
                    <button className="bt add" onClick={() => SET_STATE_PAGE("adduser")}>+ Add User</button>
                  </div>
                  <div className="content-table">
                    <table>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Username</th>
                          <th>Province ID</th>
                          <th>Level</th>
                          <th style={{ width: 160 }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          USERS.map((user, i) => {
                            return <tr key={i}>
                              <td>{user.userid}</td>
                              <td>{user.username}</td>
                              <td>{user.province_id}</td>
                              <td>{user.level}</td>
                              <td>
                                {
                                  (user.username === "demo") ? (
                                    <div style={{ color: "#999", textAlign: "center", width: "100%" }}>Not to change</div>
                                  ) : (
                                    <div className="bt-group">
                                      <button className="bt edit" onClick={() => onSelectEdit(user)}>
                                        <img src={iconEdit} />
                                      </button>
                                      <button className="bt delete" onClick={() => onConfirmDeleteUser(user)}>
                                        <img src={iconDelete} />
                                      </button>
                                    </div>
                                  )
                                }
                              </td>
                            </tr>
                          })
                        }
                        {
                          Array.from({ length: 10 - USERS.length }).map((_, i) => {
                            return (
                              <tr key={i}>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className="pagination">

                    <div>Page {pagination.currentPage} of {pagination.totalPages}</div>

                    <div style={{ flexGrow: 1 }}></div>

                    <button onClick={pagination.firstPage} disabled={pagination.currentPage === 1}>
                      <img src={iconFirst} />
                    </button>
                    <button onClick={pagination.prevPage} disabled={pagination.currentPage === 1}>
                      <img className="nextback" src={iconBack} />
                    </button>

                    <input
                      type="number"
                      value={pagination.currentPage}
                      onChange={(e) => pagination.skipPage(Number(e.target.value))}
                      min={1}
                      max={pagination.totalPages}
                    />

                    <button onClick={pagination.nextPage} disabled={pagination.currentPage === pagination.totalPages}>
                      <img className="nextback" src={iconNext} />
                    </button>
                    <button onClick={pagination.lastPage} disabled={pagination.currentPage === pagination.totalPages}>
                      <img src={iconLast} />
                    </button>

                  </div>
                </div>
              ) : null
            }

            {
              (STATE_PAGE === "adduser" || STATE_PAGE === "edituser") ? (
                <div className="content-user-management">
                  <div className="content-title">
                    <button className="bt cancel" onClick={() => onCancelAdd()}>Back</button>
                    <div className="t">{STATE_PAGE === "adduser" ? "Add" : "Edit"} user</div>
                    <div style={{ flexGrow: 1 }}></div>
                  </div>

                  <div className="content-form">
                    <div className="form-row">
                      <label>Username:</label>
                      <input
                        type="text"
                        name="username"
                        defaultValue={formData.username}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-row">
                      <label>Password:</label>
                      <input
                        type="password"
                        name="password"
                        defaultValue={formData.password}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-row">
                      <label>Level:</label>
                      <select
                        name="level"
                        defaultValue={formData.level}
                        onChange={handleChange}
                      >
                        <option value="">Select level</option>
                        <option value="admin">Admin</option>
                        <option value="manager">Manager</option>
                        <option value="staff">Staff</option>
                      </select>
                    </div>
                    <div className="form-row">
                      <label>Province ID:</label>
                      <select
                        name="province_id"
                        defaultValue={formData.province_id}
                        onChange={handleChange}
                      >
                        <option value="">Select province</option>
                        {globalState.enum.filter_province.map((en, i) => <option key={i} value={en.id}>{en.title}</option>)}
                      </select>
                    </div>


                    {
                      error_message.map((em, i) => <div key={i} className="error-message">{em}</div>)
                    }

                    <div className="form-row">
                      <button className="bt cancel" onClick={() => onCancelAdd()}>Cancel</button>
                      <button
                        className="bt add"
                        onClick={() => {
                          onSaveAdd()
                          if (STATE_PAGE === "adduser") {
                            onSaveAdd();
                          } else {
                            onSaveUpdate();
                          }
                        }}>
                        {STATE_PAGE === "adduser" ? "Add" : "Edit"}
                      </button>
                    </div>

                  </div>

                </div>
              ) : null
            }

          </div>

          <ConfirmDialog
            open={openDialog}
            onClose={handleCloseDialog}
            onConfirm={onDeleteUser}
            title="Confirm Deletion"
            message={"Are you sure you want to delete user '" + formData.username + "'?"}
          />

        </div>
      )}
    </>
  );
}

export default App;
