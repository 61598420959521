import "./styles.css";
import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";

// import videojs from "video.js";
// import "../../../node_modules/video.js/dist/video-js.css";

// import Leaflet from "leaflet"
import * as Leaflet from "leaflet";
import 'leaflet.markercluster';
import { MapContainer, TileLayer, Marker, Popup, Polyline, Polygon, ZoomControl, useMap, Circle, GeoJSON, Pane } from 'react-leaflet';
import "../../../node_modules/leaflet/dist/leaflet.css";

import Spinner from "../../component/spinner";

import ReactHlsPlayer from 'react-hls-player';

import { Chart } from "react-google-charts";
import { HttpRequest } from "../../service/HttpRequest";

import imgNoimg from "../../assets/images/noimg.png";
import iconRefresh from "../../assets/icons/refresh.png";

const iconPin = new Leaflet.Icon({
    iconUrl: require('../../assets/icons/pin-map.png'),
    iconAnchor: new Leaflet.Point(15, 44),
    popupAnchor: new Leaflet.Point(0, -44),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Leaflet.Point(30, 44)
});

const pin_square_border = `
<svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
        viewBox="0 0 256 256"
        style="enable-background:new 0 0 256 256;"
        xml:space="preserve">
    <g>
        <rect x="5" y="5" class="st0" width="246" height="246" fill="{mapIconColor}"/>
        <path d="M246,10v236H10V10H246 M256,0H0v256h256V0L256,0z"/>
    </g>
</svg>
`

const pin_circle_border = `
<svg 
    version="1.1" 
    id="Layer_1" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlns:xlink="http://www.w3.org/1999/xlink" 
    x="0px" 
    y="0px"
    viewBox="0 0 256 256"
    style="enable-background:new 0 0 256 256;"
    xml:space="preserve">
    <g>
        <circle class="st0" cx="128" cy="128" r="123" fill="{mapIconColor}"/>
        <path d="M128,10c31.5,0,61.2,12.3,83.4,34.6C233.7,66.8,246,96.5,246,128s-12.3,61.2-34.6,83.4C189.2,233.7,159.5,246,128,246
            s-61.2-12.3-83.4-34.6C22.3,189.2,10,159.5,10,128s12.3-61.2,34.6-83.4C66.8,22.3,96.5,10,128,10 M128,0C57.3,0,0,57.3,0,128
            s57.3,128,128,128s128-57.3,128-128S198.7,0,128,0L128,0z"/>
    </g>
</svg>
`

const pin_custom_image = `<img src="{image}" style="{style}" width="20" height="40"/>`

const iconPinIntersect = (color) => new Leaflet.divIcon({
    className: "leaflet-data-marker",
    html: Leaflet.Util.template(pin_square_border, {
        mapIconColor: (color ? color : '#888') /// กำหนดสีผ่านตัวแปล
    }),
    iconAnchor: new Leaflet.Point(7, 7),
    popupAnchor: new Leaflet.Point(0, -7),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Leaflet.Point(14, 14)
});

const iconPinIntersectCircle = (color) => new Leaflet.divIcon({
    className: "leaflet-data-marker",
    html: Leaflet.Util.template(pin_circle_border, {
        mapIconColor: (color ? color : '#888') /// กำหนดสีผ่านตัวแปล
    }),
    iconAnchor: new Leaflet.Point(9, 9),
    popupAnchor: new Leaflet.Point(0, -9),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Leaflet.Point(18, 18)
});

const iconPinSensor = (status) => new Leaflet.divIcon({
    className: "leaflet-data-marker",
    html: Leaflet.Util.template(pin_custom_image, {
        image: require('../../assets/icons/sensor.png'),
        style: (status === true) ? `` : `filter: grayscale(100%)`
    }),
    iconAnchor: new Leaflet.Point(20, 40),
    popupAnchor: new Leaflet.Point(0, -40),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Leaflet.Point(40, 40)
});

const iconPinCCTV = (status) => new Leaflet.divIcon({
    className: "leaflet-data-marker",
    html: Leaflet.Util.template(pin_custom_image, {
        image: require('../../assets/icons/cctv.png'),
        style: (status === true) ? `` : `filter: grayscale(100%)`
    }),
    iconAnchor: new Leaflet.Point(20, 40),
    popupAnchor: new Leaflet.Point(0, -40),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Leaflet.Point(40, 40)
});

const iconPinBluetooth = (status) => new Leaflet.divIcon({
    className: "leaflet-data-marker",
    html: Leaflet.Util.template(pin_custom_image, {
        image: require('../../assets/icons/bluetooth.png'),
        style: (status === true) ? `` : `filter: grayscale(100%)`
    }),
    iconAnchor: new Leaflet.Point(20, 40),
    popupAnchor: new Leaflet.Point(0, -40),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Leaflet.Point(40, 40)
});

const iconPinPort = new Leaflet.Icon({
    iconUrl: require('../../assets/icons/transport - port.png'),
    iconAnchor: new Leaflet.Point(10, 10),
    popupAnchor: new Leaflet.Point(0, -10),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Leaflet.Point(20, 20)
});

const iconPinAirport = new Leaflet.Icon({
    iconUrl: require('../../assets/icons/transport - airport.png'),
    iconAnchor: new Leaflet.Point(10, 10),
    popupAnchor: new Leaflet.Point(0, -10),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Leaflet.Point(20, 20)
});

const iconPinBus = new Leaflet.Icon({
    iconUrl: require('../../assets/icons/transport - bus.png'),
    iconAnchor: new Leaflet.Point(10, 10),
    popupAnchor: new Leaflet.Point(0, -10),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Leaflet.Point(20, 20)
});

const iconPinIndustrial = new Leaflet.Icon({
    iconUrl: require('../../assets/icons/transport - industrial.png'),
    iconAnchor: new Leaflet.Point(10, 10),
    popupAnchor: new Leaflet.Point(0, -10),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Leaflet.Point(20, 20)
});

const iconPinTrain = new Leaflet.Icon({
    iconUrl: require('../../assets/icons/transport - train.png'),
    iconAnchor: new Leaflet.Point(10, 10),
    popupAnchor: new Leaflet.Point(0, -10),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Leaflet.Point(20, 20)
});



const iconPinTruck = new Leaflet.Icon({
    iconUrl: require('../../assets/icons/truck.png'),
    iconAnchor: new Leaflet.Point(20, 40),
    popupAnchor: new Leaflet.Point(0, -40),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Leaflet.Point(40, 40)
});

const iconPinIncident = new Leaflet.Icon({
    iconUrl: require('../../assets/icons/incident.png'),
    iconAnchor: new Leaflet.Point(20, 40),
    popupAnchor: new Leaflet.Point(0, -40),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Leaflet.Point(40, 40)
});

function VideoPlayerComponent(props) {
    const playerRef = React.useRef();

    function playVideo() {
        playerRef.current.play();
    }

    function pauseVideo() {
        playerRef.current.pause();
    }

    function toggleControls() {
        playerRef.current.controls = !playerRef.current.controls;
    }

    React.useEffect(() => {
        if (playerRef.current) {
            playVideo();
        }
    }, []);

    return (
        <ReactHlsPlayer
            style={{ width: "-webkit-fill-available" }}
            playerRef={playerRef}
            src={props.url}
            playing={"true"}
            controls
            config={{
                file: {
                    attributes: {
                        controlsList: "nofullscreen",
                    },
                },
            }}
        />
    );
}

const CenterMap = ({ lat, lng, zoom }) => {
    const map = useMap();
    React.useEffect(() => {
        map.setView([lat, lng], zoom);
    }, [lat, lng, zoom]);
    return null;
}

const FitBoundsMap = ({ arr_lat_lon }) => {
    const map = useMap();
    React.useEffect(() => {

        if (arr_lat_lon && (arr_lat_lon.length > 0)) {
            map.fitBounds(arr_lat_lon);
        }

    }, [arr_lat_lon]);

    return null;
}

const TrafficMap = ({ data, onSelectRoute }) => {
    const map = useMap();

    React.useEffect(() => {

        // let lines = data.geojson;
        let style = {
            color: data.color,
            weight: data.size ? data.size : 5,
            opacity: data.opacity ? data.opacity : 1,
        };

        let popup_traffic = data[data.type + "_label"];

        function onEachFeature(feature, layer) {
            // does this feature have a property named popupContent?
            if (feature.properties && feature.properties.popupContent) {
                layer.bindPopup(feature.properties.popupContent);
            }

            if (onSelectRoute) {
                layer.on({
                    click: onSelectRoute()
                });
            }
        }

        var geojsonFeature = {
            "type": "Feature",
            "properties": {
                "data": data,
                "popupContent": popup_traffic
            },
            "geometry": data.geojson
        };

        Leaflet.geoJSON(geojsonFeature, {
            style: style,
            onEachFeature: onEachFeature
        }).addTo(map);

        // Leaflet.geoJSON(lines, {
        //     style: style,
        //     onEachFeature: function (feature, layer) {
        //         // control popop road_name
        //         layer.bindPopup(popup_traffic);

        //         if (onSelectRoute) {
        //             layer.on({
        //                 click: onSelectRoute(data)
        //             });
        //         }
        //     }
        // }).addTo(map);

    }, [data]);

    return null;
}


const MarkerClusterGroup = ({ data }) => {
    const map = useMap();
    const [Layers, set_Layers] = React.useState(null);

    React.useEffect(() => {
        if (!Layers) {
            let layers = new Leaflet.MarkerClusterGroup({
                iconCreateFunction: function (cluster) {
                    let color = "green";
                    if (cluster.getChildCount() > 10) {
                        color = "yellow";
                    }
                    if (cluster.getChildCount() > 100) {
                        color = "orange";
                    }
                    if (cluster.getChildCount() > 200) {
                        color = "red";
                    }
                    return Leaflet.divIcon({
                        className: ("marker-cluster " + color),
                        html: '<b>' + cluster.getChildCount() + '</b>',
                        iconAnchor: new Leaflet.Point(20, 20),
                        popupAnchor: new Leaflet.Point(0, -20),
                        shadowUrl: null,
                        shadowSize: null,
                        shadowAnchor: null,
                        iconSize: new Leaflet.Point(40, 40)
                    });
                }
            });
            set_Layers(layers);
        }

    }, []);

    React.useEffect(() => {
        if (Layers) {
            if (data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    let position = [data[i].lat, data[i].lon];
                    let option = {
                        icon: iconPinIncident
                    }
                    let marker = Leaflet.marker(position, option)
                    let popup = (
                        "เวลา : <b>"
                        + data[i].datetime + "</b><br />"
                        + "รายละเอียด : <b>"
                        + data[i].description + "</b>"
                    );
                    marker.bindPopup(popup);

                    Layers.addLayer(marker);
                }
                map.addLayer(Layers);
            } else {
                Layers.clearLayers();
            }
        }

    }, [data, Layers]);

    return null;
}

const LineChart = ({ pin, timeData }) => {

    const [DATA_CHART, SET_DATA_CHART] = React.useState(null);
    const [TITLE_CHART, SET_TITLE_CHART] = React.useState("");

    const [TIME_DATA_RANGE, SET_TIME_DATA_RANGE] = React.useState([
        {
            id: "LATEST",
            title: "เวลาล่าสุด",
            start: null,
            stop: null
        },
        {
            id: "AM",
            title: "AM Peak (06.00-09.00)",
            start: 6,
            stop: 9
        },
        {
            id: "MD",
            title: "MD Peak (11.00-13.00)",
            start: 11,
            stop: 13
        },
        {
            id: "PM",
            title: "PM Peak (16.00-19.00)",
            start: 16,
            stop: 19
        }
    ]);

    function generateDataChart(data) {

        let data_chart = [[data[0].x_label]];
        let tick_chart = []

        let h_label = (((data.length > 0) && data[0].x_label) ? data[0].x_label : "");
        let v_label = (((data.length > 0) && data[0].y_label) ? data[0].y_label : "");

        let max_h_value = 0;

        for (let i = 0; i < data.length; i++) {
            const graph = data[i];
            data_chart[0].push(graph.title);
            let stack_x_axis = null;

            for (let j = 0; j < graph.values.length; j++) {
                let x_axis = Math.floor(graph.values[j].x_float);

                let x_axis_label = (graph.values[j].x_float).toString();
                let y_axis_label = (graph.values[j].y_value);

                if (graph.values[j].y > max_h_value) {
                    max_h_value = graph.values[j].y;
                }

                if (i === 0) {
                    if (stack_x_axis !== x_axis) {
                        stack_x_axis = x_axis;
                        tick_chart.push({ v: graph.values[j].x, f: x_axis_label });
                    } else {
                        tick_chart.push({ v: graph.values[j].x, f: "" });
                    }
                }

                if (i > 0) {
                    data_chart[j + 1].push({ v: graph.values[j].y, f: y_axis_label });
                } else {
                    data_chart.push([{ v: graph.values[j].x, f: graph.values[j].x_value }, { v: graph.values[j].y, f: y_axis_label }]);
                }
            }
        }

        let find_time_range = TIME_DATA_RANGE.filter((time) => time.id === timeData);

        if (find_time_range.length > 0) {
            // set area time 
            data_chart[0].push("PEAK TIME");

            for (let i = 1; i < data_chart.length; i++) {
                let time_string = (data_chart[i][0].f).replace(":", ".");
                let time_row = parseFloat(time_string);
                // console.log(time_string + " ---> " + time_row);

                if ((time_row >= find_time_range[0].start) && (time_row <= find_time_range[0].stop)) {
                    data_chart[i].push({ v: max_h_value + 10, f: '' });
                } else {
                    data_chart[i].push({ v: null, f: 'no data' });
                }
            }
        }

        return { data: data_chart, tick: tick_chart, h_label: h_label, v_label: v_label, max_h_value: max_h_value }
    }

    async function getChart(sensor_id) {
        console.log("sensor_id : ", sensor_id);
        HttpRequest("/api/getsensorgraph", {
            "sensor_id": parseInt(sensor_id)
        }).then((data) => {
            console.log("HttpRequest /api/getsensorgraph:", data);
            if (data.result === "OK") {

                let data_speed = generateDataChart(data.data.graphs);
                let data_density = generateDataChart(data.data.graphs_density);
                let data_occupancy = generateDataChart(data.data.graphs_occupancy);
                let data_volume = generateDataChart(data.data.graphs_volume);

                let datachart = [
                    { title: "Speed", ...data_speed },
                    { title: "Density", ...data_density },
                    { title: "Occupancy", ...data_occupancy },
                    { title: "Volume", ...data_volume }
                ]
                console.log(datachart);

                let title_chart = data.data.date;
                SET_TITLE_CHART(title_chart);
                SET_DATA_CHART(datachart);
            }
        });
    }

    React.useEffect(() => {

        if (pin && pin.sensor_id) {
            getChart(pin.sensor_id);
        }

    }, [timeData]);

    return (
        (DATA_CHART) ? (
            <Fragment>
                <span style={{ marginLeft: 20 }}>Date : <b>{TITLE_CHART}</b></span>
                {
                    DATA_CHART.map((c, index) => {
                        // index
                        return <Chart
                            key={index}
                            chartType="ComboChart"
                            width="100%"
                            height="250px"
                            data={c.data}
                            options={{
                                // title: c.title,
                                title: c.v_label,
                                titleTextStyle: { color: "#000", fontSize: 15 },
                                interpolateNulls: "absolute",
                                curveType: "function",
                                legend: { position: "bottom", padding: 0 },
                                series: {
                                    0: { type: 'line' },
                                    1: { type: 'line' },
                                    2: { type: 'area', lineWidth: 0 }
                                },
                                hAxis: {
                                    title: c.h_label,
                                    // direction: -1,
                                    slantedText: true,
                                    slantedTextAngle: 270,
                                    showTextEvery: 4,
                                    ticks: c.tick,
                                    textStyle: { fontSize: 12 },
                                    gridlines: { color: "transparent" },
                                    titleTextStyle: { color: "#000", fontSize: 14 },
                                },
                                vAxis: {
                                    // title: c.v_label,
                                    viewWindow: {
                                        max: c.max_h_value + 10,
                                        min: 0
                                    }
                                },
                                chartArea: { left: 30, top: 40, right: 0, bottom: 80 },
                            }}
                        />
                    })
                }
            </Fragment>
        ) : (
            <div className="loading-popup">
                <Spinner />
                <div>กำลังโหลด</div>
            </div>
        )
    );
    // return null
}

const PopupSensor = (props) => {
    // { pin, timeData, source }
    let pin = props.pin;
    let timeData = props.timeData;
    let source = props.source;

    const navigate = useNavigate();

    const [stateTab, setStateTab] = React.useState("stream");
    const [sourceStreamImage, setSourceStreamImage] = React.useState(null);

    const [snapImage_in, set_snapImage_in] = React.useState(pin.stream_url_inbound);
    const [snapImage_out, set_snapImage_out] = React.useState(pin.stream_url_outbound);

    const onNavigatePage = (path) => {
        navigate(path);
    };

    function onRefreshSnapImage() {
        set_snapImage_in(pin.stream_url_inbound + "?" + Date.now());
        set_snapImage_out(pin.stream_url_outbound + "?" + Date.now());
    }

    React.useEffect(() => {

        let find_source = source.filter((s) => pin.source === s.id);
        if (find_source.length > 0) {
            setSourceStreamImage(find_source[0]);
        }

    }, [props]);

    React.useEffect(() => {
        const RefreshInternal = setInterval(() => {
            set_snapImage_in(pin.stream_url_inbound + "?" + Date.now());
            set_snapImage_out(pin.stream_url_outbound + "?" + Date.now());
        }, 60000);
        return () => {
            clearInterval(RefreshInternal);
        };
    }, []);

    return (
        <Popup maxWidth={330} minWidth={330}>
            <div className="popup-sensor-container">

                <div className="source-stream">
                    {
                        (sourceStreamImage && sourceStreamImage.icon) ? (
                            <img src={sourceStreamImage.icon} />
                        ) : (null)
                    }
                    <div className="source-stream-detail">
                        {
                            (sourceStreamImage && sourceStreamImage.title) ? (
                                <span><b>{sourceStreamImage.title}</b></span>
                            ) : (null)
                        }

                        {/** show & close button report card popup */}
                        {pin.online ? (
                            <button
                                className="bt-report"
                                style={{ background: ((!pin.report) ? "gray" : "") }}
                                onClick={() => {
                                    if (pin.report) {
                                        onNavigatePage("/report?report_id=SPEED&sensor_id=" + pin.sensor_id)
                                    }
                                }}>REPORT</button>
                        ) : (null)}
                        {/* <button className="bt-report" onClick={() => onCheckData(pin)}>DATA</button> */}
                    </div>

                </div>

                <div className="header">
                    {/* header-box (pin.code)*/}
                    <div className="code">{pin.name}</div>
                    <div className="tab-bt-container">
                        <div className={"bt " + ((stateTab === "stream") ? "active" : "")} onClick={() => setStateTab("stream")}>Stream</div>
                        <div className={"bt " + ((stateTab === "detail") ? "active" : "")} onClick={() => setStateTab("detail")}>Detail</div>
                        <div className={"bt " + ((stateTab === "chart") ? "active" : "")} onClick={() => setStateTab("chart")}>Chart</div>
                    </div>
                </div>
                {
                    (stateTab === "detail") ? (
                        <div className="content">
                            <div className="inner-html" dangerouslySetInnerHTML={{ __html: pin.sensor_detail }}></div>
                        </div>
                    ) : (null)
                }
                {
                    (stateTab === "stream") ? (
                        <div className="content">
                            <span style={{ marginBottom: 5 }}>
                                Stream Type : <b>{pin.stream_type}</b>
                            </span>
                            {
                                (pin.stream_type === "html" || pin.stream_type === "snapshot") ? (
                                    <button className="bt-report" onClick={() => onRefreshSnapImage()} style={{
                                        marginLeft: 10,
                                        marginTop: -5
                                    }}>
                                        <img src={iconRefresh} width={20} height={20} />
                                    </button>
                                ) : (null)
                            }
                            <br />
                            {
                                pin.stream_url_inbound ? (
                                    <Fragment>
                                        <b>{pin.desc_inbound}</b><br />
                                        {
                                            (pin.stream_type === "rtsp") ? (
                                                <VideoPlayerComponent url={pin.stream_url_inbound} />
                                            ) : (pin.stream_type === "html" || pin.stream_type === "snapshot") ? (
                                                <img
                                                    src={snapImage_in}
                                                    className="img-html-content-video"
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.src = imgNoimg;
                                                    }} />
                                            ) : (
                                                <VideoPlayerComponent url={pin.stream_url_inbound} />
                                            )
                                        }
                                    </Fragment>
                                ) : (null)
                            }
                            <br /><br />
                            {
                                pin.stream_url_outbound ? (
                                    <Fragment>
                                        <b>{pin.desc_outbound}</b><br />
                                        {
                                            (pin.stream_type === "rtsp") ? (
                                                <VideoPlayerComponent url={pin.stream_url_outbound} />
                                            ) : (pin.stream_type === "html" || pin.stream_type === "snapshot") ? (
                                                <img
                                                    src={snapImage_out}
                                                    className="img-html-content-video"
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.src = imgNoimg;
                                                    }} />
                                            ) : (
                                                <VideoPlayerComponent url={pin.stream_url_outbound} />
                                            )
                                        }
                                    </Fragment>
                                ) : (null)
                            }
                            <br />
                        </div>
                    ) : (null)
                }
                {
                    (stateTab === "chart") ? (
                        <div className="content">
                            {/* <span>Date :  <b>{data_chart.title} [ {data_chart.date} ]</b></span> */}
                            <LineChart pin={pin} timeData={timeData}></LineChart>
                        </div>
                    ) : (null)
                }
            </div>
        </Popup>
    )
}



function MyApp(props) {

    // const polylineOptions = { color: 'red' }
    // const polyline = [
    //     [13.768015142096976, 100.51147590581603],
    //     [13.756402170622884, 100.50607436617001],
    //     [13.75604846701497, 100.50510330286288],
    //     [13.757758029501042, 100.49642442455526],
    //     [13.757463278239252, 100.49496782959453],
    //     [13.768427775145701, 100.48574272817667],
    // ]


    const [center, setCenter] = React.useState(props.center);
    const [zoom, setZoom] = React.useState(props.zoom);

    const [PolygonData, setPolygonData] = React.useState(null);
    const [PolylineData, setPolylineData] = React.useState(null);
    const [CircleData, setCircleData] = React.useState(null);
    const [TrafficData, setTrafficData] = React.useState(null);
    const [PinmapData, setPinmapData] = React.useState(null);
    const [ClusterData, setClusterData] = React.useState(null);

    const [timeData, setTimeData] = React.useState(null);
    const [sourceData, setSourceData] = React.useState([]);

    // const [zoom, setZoom] = React.useState(props.zoom);

    React.useEffect(() => {
        const controller = new AbortController();

        // console.log(props.polygon);

        // console.log("useEffect props open street map component", props);
        if (props.center && props.zoom) {
            setCenter(props.center);
            setZoom(props.zoom);
        }

        return () => {
            controller.abort();
        };
    }, [props.center, props.zoom]);


    React.useEffect(() => {
        const controller = new AbortController();

        if (props.polygon) { setPolygonData(props.polygon) }
        if (props.circle) { setCircleData(props.circle) }
        if (props.traffic) { setTrafficData(props.traffic) }
        if (props.pinmap) { setPinmapData(props.pinmap) }
        if (props.cluster) { setClusterData(props.cluster) }
        if (props.source) { setSourceData(props.source) }

        if (props.time) {
            if (props.time !== "LATEST") {
                setTimeData(props.time)
            } else {
                setTimeData(null)
            }
        } else {
            setTimeData(null)
        }

        return () => {
            controller.abort();
        };
    }, [
        props.polygon,
        props.circle,
        props.traffic,
        props.pinmap,
        props.cluster,
        props.source,
    ]);

    React.useEffect(() => {
        const controller = new AbortController();

        if (props.polyline) {
            setPolylineData(null);
            setTimeout(() => {
                setPolylineData(props.polyline);
            }, 1);
        }

        return () => {
            controller.abort();
        };
    }, [props.polyline]);


    return (
        <MapContainer
            center={center}
            zoom={zoom}
            zoomControl={false}
            scrollWheelZoom={true}
            style={{ width: "100%", height: "auto", flexGrow: 1, flexBasis: 0 }}>

            <CenterMap lat={center[0]} lng={center[1]} zoom={zoom} />

            <FitBoundsMap arr_lat_lon={props.fitBoundsRef} />
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {
                TrafficData ? (
                    TrafficData.map((trafficline, index) => {
                        return <TrafficMap key={index} data={trafficline} onSelectRoute={props.onSelectRoute}></TrafficMap>;
                    })
                ) : (null)
            }

            {/* {
                TrafficData ? (
                    TrafficData.map((trafficline, index) => {
                        if (trafficline.focus) {
                            return (
                                <Polyline
                                    key={index}
                                    weight={5}
                                    color={trafficline.color}
                                    positions={trafficline.geojson.coordinates}>
                                    <Popup >
                                        Link ID : <b>{trafficline.link_id}</b><br />
                                        จังหวัด : <b>{trafficline.province_name}</b><br />
                                        AVG.Speed : <b>{parseFloat(trafficline.avg_speed_kph).toFixed(1)}</b><br />
                                        Total Vehicle :<b>{trafficline.total_vehicle}</b>
                                    </Popup>
                                </Polyline>
                            );

                        } else {
                            return null;
                        }
                    })
                ) : (null)
            } */}

            {
                ClusterData ? (
                    <MarkerClusterGroup data={ClusterData}></MarkerClusterGroup>
                ) : (null)
            }
            <div name="polygon-layer" style={{ zIndex: 501 }}>
                {
                    PolygonData ? (
                        PolygonData.map((polygon , index) => {
                            let color = "purple"; // color base map polygon
                            let weight = 10;

                            if (polygon.pin_type) {
                                weight = 1;
                            }

                            if (polygon.place_type === "train") {
                                weight = 5;
                                color = "#7f5101";
                            }

                            return (
                                
                                <GeoJSON
                                    key={index}
                                    color={color}
                                    data={polygon.geojson}
                                    weight={weight}
                                    fillOpacity={0.1}
                                    opacity={polygon.opacity ? polygon.opacity : 1}
                                >
                                    <Popup>
                                        <b> {polygon.name} </b>
                                        {
                                            polygon.label ? (
                                                <>
                                                    <br /><b> {polygon.label} </b>
                                                </>
                                            ) : (null)
                                        }

                                    </Popup>
                                </ GeoJSON>
                            );
                        })
                    ) : (null)
                }
            </div>

            <div name="polyline-layer" style={{ zIndex: 502 }}>
                {
                    PolylineData ? (
                        PolylineData.map((polyline, index) => {
                            return (
                                <Polyline
                                    key={index}
                                    weight={polyline.size ? polyline.size : 5}
                                    color={polyline.color}
                                    opacity={polyline.opacity ? polyline.opacity : 1}
                                    positions={polyline.geojson.coordinates}>
                                    {
                                        (polyline.type_polyline === "od") ? (

                                            <Popup>
                                                <div className="inner-html" dangerouslySetInnerHTML={{ __html: polyline.od_label }}></div>
                                            </Popup>
                                        ) : (null)
                                    }
                                </Polyline>
                            );
                        })
                    ) : (null)
                }
            </div>
            <div name="circle-layer" style={{ zIndex: 503 }}>
                {
                    CircleData ? (
                        CircleData.map((circle, index) => {
                            return (
                                (circle.pin_type === "intersect") ? (

                                    <Circle
                                        key={index}
                                        center={[circle.lat, circle.lon]}
                                        pathOptions={{ fillColor: "#9605FB", color: "#9605FB", fillOpacity: "0.5" }}
                                        radius={circle.raduis_m ? circle.raduis_m : 100}
                                    >
                                        <Popup>
                                            {circle.desc}<br />
                                            {circle.name}
                                        </Popup>
                                    </Circle>
                                ) : (
                                    <Circle
                                        key={index}
                                        center={[circle.lat, circle.lon]}
                                        pathOptions={{ fillColor: circle.color ? circle.color : 'red' }} // color of circle
                                        radius={circle.raduis_m}
                                    >
                                        <Popup>
                                            <b> {circle.name} </b><br />
                                            <span style={{ color: "blue" }}>{circle.place_type}</span>
                                        </Popup>
                                    </Circle>
                                )
                            );
                        })
                    ) : (null)
                }
            </div>
            <div name="marker-layer" style={{ zIndex: 504 }}>
                {
                    PinmapData ? (
                        PinmapData.map((pin, index) => {
                            let iconMarker = iconPinTruck;
                            switch (pin.pin_type) {

                                case "intersect":
                                    iconMarker = iconPinIntersect(pin.color);
                                    break;
                                case "intersect_trafficlight":
                                    iconMarker = iconPinIntersectCircle(pin.color);
                                    break;
                                case "radar":
                                    iconMarker = iconPinSensor(pin.online); {/**/ }
                                    break;
                                case "cctv":
                                    iconMarker = iconPinCCTV(pin.online);
                                    break;
                                case "bluetooth":
                                    iconMarker = iconPinBluetooth(pin.online);
                                    break;
                                case "industrialestate":
                                    iconMarker = iconPinIndustrial;
                                    break;
                                case "port":
                                    iconMarker = iconPinPort;
                                    break;
                                case "airport":
                                    iconMarker = iconPinAirport;
                                    break;
                                case "bus":
                                    iconMarker = iconPinBus;
                                    break;
                                case "train":
                                    iconMarker = iconPinTrain;
                                    break;

                                default:
                                    break;
                            }

                            return ((pin.none_action) ? (
                                <Marker
                                    key={index}
                                    icon={iconMarker}
                                    position={[pin.lat, pin.lon]}

                                ></Marker>
                            ) : (
                                <Marker
                                    key={index}
                                    icon={iconMarker}
                                    position={[pin.lat, pin.lon]}
                                    ref={e => pin.markerRef = { current: e }}  // Set the marker reference เปิด ป๊อบ
                                >

                                    {   /* Control Box data  ()*/
                                        (pin.pin_type === "radar" || pin.pin_type === "bluetooth" || pin.pin_type === "cctv") ? (
                                            <PopupSensor pin={pin} timeData={timeData} source={sourceData}></PopupSensor>
                                        ) : ((pin.pin_type === "intersect") || (pin.pin_type === "intersect_trafficlight")) ? (
                                            <Popup>
                                                {
                                                    pin.data_dashboard ? (
                                                        <div className="inner-html" dangerouslySetInnerHTML={{ __html: pin.data_dashboard.intersect_label }}></div>
                                                    ) : (
                                                        pin.desc ? (
                                                            <Fragment>
                                                                {pin.name}<br />
                                                                <div className="inner-html" dangerouslySetInnerHTML={{ __html: pin.desc }}></div>
                                                            </Fragment>
                                                        ) : (
                                                            <div className="inner-html" dangerouslySetInnerHTML={{ __html: pin.intersect_label }}></div>
                                                        )

                                                    )
                                                }
                                            </Popup>
                                        ) : (
                                            (pin.pin_type === "industrialestate") ||
                                            (pin.pin_type === "airport") ||
                                            (pin.pin_type === "port") ||
                                            (pin.pin_type === "bus") ||
                                            (pin.pin_type === "train")
                                        ) ? (
                                            <Popup>
                                                {pin.name}
                                            </Popup>
                                        ) : (
                                            <Popup >
                                                ประเภทรถบรรทุก: {pin.vehicle_type}<br />
                                                เวลา: {pin.time}<br />
                                                ความเร็ว: {parseFloat(pin.speed_kph).toFixed(1)} กม/ชม.<br />
                                                <span style={{ color: "red" }}>{pin.pin_type}</span>
                                            </Popup>
                                        )
                                    }
                                </Marker>)
                            );
                        })
                    ) : (null)
                }
            </div>

            <ZoomControl position="bottomright" />

        </MapContainer>
    )
}

export default MyApp;