import React from "react";
import { Navigate } from "react-router-dom";
import Layout from "../layout/main-layout";

// const LOGIN_PAGE = React.lazy(() => import("../page/login"));

// const TRAFFIC_PAGE = React.lazy(() => import("../page/traffic"));
// const SAFETY_PAGE = React.lazy(() => import("../page/safety"));
// const LOGISTICS_PAGE = React.lazy(() => import("../page/logistic"));
// const REPORT_PAGE = React.lazy(() => import("../page/report"));


import LOGIN_PAGE from "../page/login";
import DASHBOARD_PAGE from "../page/dashboard";
import TRAFFIC_PAGE from "../page/traffic";
import SAFETY_PAGE from "../page/safety";
import LOGISTICS_PAGE from "../page/logistic";
import REPORT_PAGE from "../page/report";
import USERS_PAGE from "../page/users";

const first_page = "dashboard";

const routeList = [
    // { path: "dashboard", name: "dashboard", displayName: "DASHBOARD", element: <DASHBOARD_PAGE />, enable: true },
    { path: "dashboard", name: "dashboard", displayName: "EEC Dashboard", element: <DASHBOARD_PAGE />, enable: true },
    { path: "traffic", name: "traffic", displayName: "TRAFFIC", element: <TRAFFIC_PAGE />, enable: true },
    { path: "safety", name: "safety", displayName: "SAFETY", element: <SAFETY_PAGE />, enable: true },
    { path: "logistics", name: "logistics", displayName: "LOGISTICS", element: <LOGISTICS_PAGE />, enable: true },
    { path: "report", name: "report", displayName: "REPORT", element: <REPORT_PAGE />, enable: true },
    { path: "users", name: "users", displayName: "USER MANAGEMENT", element: <USERS_PAGE />, enable: true },
]

export const unauthorizedRoutes = [
    {
        path: "/",
        element: <Layout />,
        children: [
            { index: true, element: <Navigate to={first_page} />, enable: false },
            // { path: "dashboard", name: "dashboard", displayName: "DASHBOARD", element: <DASHBOARD_PAGE />, enable: true },
            { path: "dashboard", name: "dashboard", displayName: "EEC Dashboard", element: <DASHBOARD_PAGE />, enable: true },
            { path: "traffic", displayName: "TRAFFIC", index: true, element: <TRAFFIC_PAGE />, enable: true },
            { path: "login", displayName: "LOGIN", element: <LOGIN_PAGE />, enable: false }
        ]
    },
    {
        path: "*",
        element: <Navigate to={first_page} />
    }
];

export const getRoutes = (auth) => {

    let authorizedRoutes = [
        {
            path: "/",
            element: <Layout />,
            children: [
                { index: true, element: <Navigate to={first_page} />, enable: false },
                { path: "login", displayName: "LOGIN", element: <LOGIN_PAGE />, enable: false }
            ]
        },
    ]

    if (auth && auth.permission) {
        console.log(auth.permission);
        for (let i = 0; i < routeList.length; i++) {
            if (auth.permission[routeList[i].path]) {
                authorizedRoutes[0].children.push(routeList[i]);
            }
        }
        return authorizedRoutes;
    } else {
        return unauthorizedRoutes;
    }
};
