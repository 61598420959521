import React from 'react';

const usePagination = (initialCount = 10) => {
    const [index, setIndex] = React.useState(0);
    const [count] = React.useState(initialCount);
    const [totalRows, setTotalRows] = React.useState(0);
    const [totalPages, setTotalPages] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);

    const setTotalCount = (total) => {
        setTotalRows(total);
        setTotalPages(Math.ceil(total / count));
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setIndex((prevIndex) => prevIndex + count);
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setIndex((prevIndex) => prevIndex - count);
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    const firstPage = () => {
        setIndex(0);
        setCurrentPage(1);
    };

    const lastPage = () => {
        const lastIndex = (totalPages - 1) * count;
        setIndex(lastIndex);
        setCurrentPage(totalPages);
    };

    const skipPage = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setIndex((pageNumber - 1) * count);
            setCurrentPage(pageNumber);
        }
    };

    return {
        index,
        count,
        currentPage,
        totalPages,
        nextPage,
        prevPage,
        firstPage,
        lastPage,
        skipPage,
        setTotalCount // Function to set the total count externally
    };
};

export default usePagination;
