import "./styles.css";
import React, { useEffect, useState } from "react";

import { HttpRequest, HttpRequestForCSV } from "../../service/HttpRequest";

import Spinner from "../../component/spinner";
import OpenStreetMap from "../../component/insane-openstreet-map";

import InsaneCollapseContent from "../../component/insane-collapse-content";
import InsaneRatioBar from "../../component/insane-ratio-bar";
import InsaneDatePicker from "../../component/insane-date-picker";
// import InsaneLoadingProgress from "../../component/insane-loading-progress";
import { HintButton } from "../../component/insane-hint-info";

import iconPinPort from "../../assets/icons/transport - port.png";
import iconPinAirport from "../../assets/icons/transport - airport.png";
import iconPinBus from "../../assets/icons/transport - bus.png";
import iconPinIndustrial from "../../assets/icons/transport - industrial.png";
import iconPinTrain from "../../assets/icons/transport - train.png";

import iconRadar from "../../assets/icons/sensor.png";
import iconCCTV from "../../assets/icons/cctv.png";
import iconBluetooth from "../../assets/icons/bluetooth.png";

import iconPinIntersect from "../../assets/icons/pin-intersec.png";
import iconPinIntersectLight from "../../assets/icons/pin-intersec-trafficlight.png";

import { useGlobalContext } from "../../service/GlobalContext";

import WKX from "wkx";

function App() {
  //// Table Parametor
  const [loading, setLoading] = React.useState(false);
  const [loadingMap, setLoadingMap] = React.useState(false);

  const [globalState, setGlobalState] = useGlobalContext();

  const [search_date, set_search_date] = React.useState("");
  const [search_time, set_search_time] = React.useState("LATEST");
  const [search_province, set_search_province] = React.useState("");

  const [legendLabel, setLegendLabel] = React.useState([]);

  const [legendLabelIntersects, setLegendLabelIntersects] = React.useState([]);
  const [legendLabelIntersectsTrafficLight, setLegendLabelIntersectsTrafficLight] = React.useState([]);

  const [PIN_MAP, SET_PIN_MAP] = React.useState([]);
  const [POLYGON_MAP, SET_POLYGON_MAP] = React.useState([]);
  const [TRAFFIC_MAP, SET_TRAFFIC_MAP] = React.useState([]);
  const [CIRCLE_MAP, SET_CIRCLE_MAP] = React.useState([]);

  const [fitBounds_MAP_ref, set_fitBounds_MAP_ref] = React.useState(null);

  const [ENUM, SET_ENUM] = React.useState(null);
  const [PEAKOPTION, SET_PEAKOPTION] = React.useState([]);
  const [PROVINCES, SET_PROVINCES] = React.useState([]);
  const [SOURCE, SET_SOURCE] = React.useState([]);

  const [FILTER_TRAFFIC_OPTION, SET_FILTER_TRAFFIC_OPTION] = React.useState([]);
  const [OPTIONS_SENSOR_TYPE, SET_OPTIONS_SENSOR_TYPE] = React.useState([]);

  const [DATA_TRAFFIC, SET_DATA_TRAFFIC] = React.useState(null);
  const [DATA_INTERSECT, SET_DATA_INTERSECT] = React.useState(null);
  const [DATA_PLACE, SET_DATA_PLACE] = React.useState(null);
  const [DATA_SENSOR, SET_DATA_SENSOR] = React.useState(null);

  const [DISPLAY_MAP_LINK, SET_DISPLAY_MAP_LINK] = React.useState(true);
  const [DISPLAY_MAP_INTERSECT, SET_DISPLAY_MAP_INTERSECT] = React.useState(false);
  const [DISPLAY_MAP_INTERSECT_TRAFFIC_LIGHT, SET_DISPLAY_MAP_INTERSECT_TRAFFIC_LIGHT] = React.useState("");

  const [DISPLAY_MAP_INDUSTRIAL, SET_DISPLAY_MAP_INDUSTRIAL] = React.useState(false);
  const [DISPLAY_MAP_PORT, SET_DISPLAY_MAP_PORT] = React.useState(false);
  const [DISPLAY_MAP_AIRPORT, SET_DISPLAY_MAP_AIRPORT] = React.useState(false);
  const [DISPLAY_MAP_BUS, SET_DISPLAY_MAP_BUS] = React.useState(false);
  const [DISPLAY_MAP_TRAIN, SET_DISPLAY_MAP_TRAIN] = React.useState(false);

  const [DATA_MAP_LINK, SET_DATA_MAP_LINK] = React.useState(null);
  const [DATA_MAP_INTERSECT, SET_DATA_MAP_INTERSECT] = React.useState(null);

  const [DATA_MAP_INDUSTRIAL, SET_DATA_MAP_INDUSTRIAL] = React.useState([]);
  const [DATA_MAP_PORT, SET_DATA_MAP_PORT] = React.useState([]);
  const [DATA_MAP_AIRPORT, SET_DATA_MAP_AIRPORT] = React.useState([]);
  const [DATA_MAP_BUS, SET_DATA_MAP_BUS] = React.useState([]);
  const [DATA_MAP_TRAIN, SET_DATA_MAP_TRAIN] = React.useState(false);

  const [state_filter, set_state_filter] = React.useState(false);
  const [state_detail, set_state_detail] = React.useState(false);

  const [mapSettingDefault, setMapSettingDefault] = React.useState({
    center: [13.762201991756932, 100.50106010074637],
    zoom: 10,
    raduis_m: 1000,
  });

  function downloadCSV(csvContent, fileName) {
    //console.log(csvContent);
    let encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName + ".csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  }

  async function getTraffic(search_province) {
    await HttpRequest("/api/getlink", {
      center_lat: 13.28772312454414,
      center_lon: 101.32467877424523,
      raduis_m: 1000,
      date: search_date,
      peak_id: search_time,
      province_id: search_province, // ใช้ search_province ที่ส่งเข้ามา
      vehicle_category: "all",
      index: 0,
      count: 0,
      wkb: true,
    }).then((data) => {
      console.log("HttpRequest getTraffic :", data);
      if (data.result === "OK") {
        for (let i = 0; i < data.links.length; i++) {
          let link = data.links[i];
          let decode = Buffer.from(link.wkb, "base64");
          link.geojson = WKX.Geometry.parseTwkb(decode).toGeoJSON();
        }
        SET_DATA_TRAFFIC(data);
        SET_DATA_MAP_LINK(data.links);
      }
    });
  }

  async function downloadMostLink() {
    await HttpRequestForCSV("/api/downloadmostlink", {
      center_lat: 13.28772312454414,
      center_lon: 101.32467877424523,
      raduis_m: 1000,
      date: search_date,
      peak_id: search_time,
      province_id: search_province,
      index: 0,
      count: 0,
    }).then((data) => {
      // console.log("HttpRequest downloadmostlink :", data);
      let fileName = "MostLink_" + search_date + "_" + search_time;
      downloadCSV(data, fileName);
    });
  }

  async function getSensor() {
    await HttpRequest("/api/getsensor", {
      sensor_id: null,
      date: search_date,
      peak_id: search_time,
      province_id: search_province,
      "index:": null,
      count: null,
    }).then((data) => {
      console.log("HttpRequest getsensor :", data);
      if (data.result === "OK") {
        SET_DATA_SENSOR(data.sensors);
      }
    });
  }

  async function getIntersect() {
    await HttpRequest("/api/getintersect", {
      intersect_id: null,
      date: search_date,
      peak_id: search_time,
      province_id: search_province,
      trafficlight: null,
      "index:": null,
      count: null,
    }).then((data) => {
      console.log("HttpRequest getintersect :", data);
      if (data.result === "OK") {
        SET_DATA_INTERSECT(data);
        SET_DATA_MAP_INTERSECT(data.intersects);
      }
    });
  }

  async function downloadMostIntersect() {
    // setLoading(true);
    await HttpRequestForCSV("/api/downloadmostintersect", {
      center_lat: 13.28772312454414,
      center_lon: 101.32467877424523,
      raduis_m: 1000,
      date: search_date,
      peak_id: search_time,
      province_id: search_province,
      index: 0,
      count: 0,
    }).then((data) => {
      // console.log("HttpRequest downloadmostintersect :", data);
      let fileName = "MostIntersect_" + search_date + "_" + search_time;
      downloadCSV(data, fileName);
    });
  }

  async function getPlace() {
    await HttpRequest("/api/getplace", {
      search: "",
      province_id: search_province,
      "index:": 0,
      count: 0,
    }).then((data) => {
      console.log("HttpRequest getplace :", data);
      if (data.result === "OK") {
        let places = data.places;

        let industrialestate = [];
        let airport = [];
        let port = [];
        let bus = [];
        let train = [];

        for (let i = 0; i < places.length; i++) {
          const place = places[i];
          switch (place.place_type) {
            case "industrialestate":
              industrialestate.push(place);
              break;
            case "airport":
              airport.push(place);
              break;
            case "port":
              port.push(place);
              break;
            case "bus":
              bus.push(place);
              break;
            case "train":
              train.push(place);
              break;

            default:
              break;
          }
        }

        SET_DATA_MAP_INDUSTRIAL(industrialestate);
        SET_DATA_MAP_AIRPORT(airport);
        SET_DATA_MAP_PORT(port);
        SET_DATA_MAP_BUS(bus);
        SET_DATA_MAP_TRAIN(train);
        SET_DATA_PLACE(places);
      } else {
      }
    });
  }

  function onDownloadMost5(id) {
    console.log(id);
    switch (id) {
      case "most5roads":
        downloadMostLink();
        break;
      case "most5intersects":
        downloadMostIntersect();
        break;

      default:
        break;
    }
  }

  function formatedate(date = new Date()) {
    let d = new Date(date);
    let dd = d.getDate();
    let mm = d.getMonth() + 1;
    let yy = d.getFullYear();

    function zerobefore(n) {
      return n > 9 ? n : "0" + n;
    }

    return yy + "-" + zerobefore(mm) + "-" + zerobefore(dd);
  }

  function getCurrentHour() {
    let d = new Date();
    return d.getHours();
  }

  function getDisplayFilter(main_key, display_key, find_key, find_value) {
    // console.log(ENUM);
    if (ENUM) {
      let arr_filter = ENUM[main_key];
      if (arr_filter) {
        let result = arr_filter.filter((e) => e[find_key] === find_value);
        if (result.length > 0) {
          return result[0][display_key];
        } else {
          return "";
        }
      } else {
        return "";
      }
    }
  }

  function panMapForMost5(list_most5) {
    if (list_most5.type === "most5roads") {
      let polylines = [...TRAFFIC_MAP];

      let boundsForFit = [];

      for (let i = 0; i < polylines.length; i++) {
        for (let j = 0; j < list_most5.link_ids.length; j++) {
          if (polylines[i].link_id === list_most5.link_ids[j]) {
            let intitialbounds = [...polylines[i].geojson.coordinates];
            for (let j = 0; j < intitialbounds.length; j++) {
              intitialbounds[j].reverse();
            }
            boundsForFit = [...boundsForFit, ...intitialbounds];

            polylines[i].focus = true;
          } else {
          }
        }
      }

      SET_DATA_MAP_LINK([]);
      setLoadingMap(true);

      setTimeout(() => {
        SET_DATA_MAP_LINK(polylines);
        setLoadingMap(false);
        set_fitBounds_MAP_ref(boundsForFit);
      }, 2000);
    } else {
      let findIntersects = DATA_INTERSECT.intersects.filter(
        (intersect) => intersect.intersect_id === list_most5.intersect_id
      );
      if (findIntersects.length > 0) {
        set_fitBounds_MAP_ref([[findIntersects[0].lat, findIntersects[0].lon]]);
      }
    }
  }

  function clearMapForMost5() {
    let polylines = [...TRAFFIC_MAP];

    for (let i = 0; i < polylines.length; i++) {
      polylines[i].opacity = 1;
    }

    SET_TRAFFIC_MAP([]);
    setLoadingMap(true);

    setTimeout(() => {
      SET_TRAFFIC_MAP(polylines);
      setLoadingMap(false);
      set_fitBounds_MAP_ref([]);
    }, 2000);
  }

  React.useEffect(() => {
    const controller = new AbortController();

    let current_date = formatedate();
    set_search_date(current_date);

    // getEnum();

    return () => {
      controller.abort();
    };
  }, []);

  React.useEffect(() => {
    const controller = new AbortController();

    if (globalState.enum) {
      let data_enum = { ...globalState.enum };

      let new_state_option_map = {
        ...mapSettingDefault,
        center: [data_enum.default.center_lat, data_enum.default.canter_lon],
        raduis_m: data_enum.default.raduis_m,
      };

      setLegendLabel(data_enum.condition_traffic);
      setLegendLabelIntersects(data_enum.condition_intersect);
      setLegendLabelIntersectsTrafficLight(data_enum.condition_intersect_trafficlight);

      setMapSettingDefault(new_state_option_map);
      SET_PEAKOPTION(data_enum.peak);
      SET_PROVINCES(data_enum.filter_province);
      SET_SOURCE(data_enum.source);

      SET_OPTIONS_SENSOR_TYPE(data_enum.sensor_type);

      if (data_enum.filter_province.length > 0) {
        set_search_province(data_enum.filter_province[0].id);
      }

      for (let i = 0; i < data_enum.filter_traffic.length; i++) {
        data_enum.filter_traffic[i].lists = [];
      }

      SET_FILTER_TRAFFIC_OPTION(data_enum.filter_traffic);

      SET_ENUM(data_enum);
    }

    return () => {
      controller.abort();
    };
  }, [globalState.enum]);

  React.useEffect(() => {
    const controller = new AbortController();

    async function init() {
      setLoading(true);
      SET_PIN_MAP([]);
      SET_POLYGON_MAP([]);
      SET_CIRCLE_MAP([]);
      SET_TRAFFIC_MAP([]);

      // ดึงค่า search_province จาก localStorage หรือค่าเริ่มต้น
      const storedProvince =
        localStorage.getItem("search_province") || search_province;

      await getIntersect();
      await getSensor();
      await getPlace();
      await getTraffic(storedProvince); // เรียก getTraffic ตาม storedProvince
      setLoading(false);
    }

    if (ENUM) {
      init();
    }

    return () => {
      controller.abort();
    };
  }, [search_date, search_time, search_province]); // re-run เมื่อ search_date, search_time, หรือ search_province เปลี่ยน

  // Save selected province to localStorage เมื่อเปลี่ยน search_province
  React.useEffect(() => {
    if (search_province) {
      localStorage.setItem("search_province", search_province);
    }
  }, [search_province]);

  // Retrieve selected province from localStorage เมื่อโหลดหน้าใหม่
  React.useEffect(() => {
    const savedProvince = localStorage.getItem("search_province");
    if (savedProvince) {
      set_search_province(savedProvince);
    }
  }, []);

  React.useEffect(() => {
    const controller = new AbortController();

    if (DATA_TRAFFIC && DATA_INTERSECT) {
      let filter_traffic = [...FILTER_TRAFFIC_OPTION];

      for (let i = 0; i < FILTER_TRAFFIC_OPTION.length; i++) {
        const key = FILTER_TRAFFIC_OPTION[i].id;

        if (DATA_TRAFFIC[key]) {
          filter_traffic[i].lists = DATA_TRAFFIC[key];
        }

        if (DATA_INTERSECT[key]) {
          filter_traffic[i].lists = DATA_INTERSECT[key];
        }

        let count_max = 0;

        for (let j = 0; j < filter_traffic[i].lists.length; j++) {
          let value = filter_traffic[i].lists[j].value;
          if (value > count_max) {
            count_max = value;
          }
          filter_traffic[i].lists[j].type = key;
        }

        for (let j = 0; j < filter_traffic[i].lists.length; j++) {
          filter_traffic[i].lists[j].total = count_max;
          filter_traffic[i].lists[j].percent_value = (
            (filter_traffic[i].lists[j].value / count_max) *
            100
          ).toFixed(1);
        }
      }

      SET_FILTER_TRAFFIC_OPTION(filter_traffic);
    }

    return () => {
      controller.abort();
    };
  }, [DATA_TRAFFIC, DATA_INTERSECT]);

  React.useEffect(() => {
    const controller = new AbortController();

    if (DATA_TRAFFIC) {
      if (DISPLAY_MAP_LINK && DATA_MAP_LINK) {
        setLoadingMap(true);

        let polyline = [...DATA_MAP_LINK];

        for (let i = 0; i < polyline.length; i++) {
          polyline[i].color = "#999";
          let status = ENUM.condition_traffic.filter(
            (legend) => polyline[i].status === legend.status
          );

          polyline[i].type = "traffic";

          if (status.length > 0) {
            if (polyline[i].focus) {
              // console.log(polyline[i]);
              polyline[i].color = "#46e2fa";
            } else {
              polyline[i].color = status[0].color;
            }
          }
        }

        SET_TRAFFIC_MAP(polyline);

        setTimeout(() => {
          setLoadingMap(false);
        }, 3000);
      } else {
        setLoadingMap(true);

        setTimeout(() => {
          SET_TRAFFIC_MAP([]);
          setLoadingMap(false);
        }, 1000);
      }
    }

    // setLoadingMap(false);

    return () => {
      controller.abort();
    };
  }, [DISPLAY_MAP_LINK, DATA_MAP_LINK]);

  /* new code */
  React.useEffect(() => {
    SET_PIN_MAP([]);
    SET_POLYGON_MAP([]);

    let isDataSelected = false;

    if (DATA_TRAFFIC && DATA_PLACE) {
      let pins = [];
      let polygons = [];

      // Handle Sensor Data based on Checkbox Selection
      if (DATA_SENSOR) {
        OPTIONS_SENSOR_TYPE.forEach((sensorType) => {
          if (sensorType.checked) {
            isDataSelected = true; // Mark as selected if any sensorType is checked

            let filteredSensors = DATA_SENSOR.filter(
              (sensor) => sensor.sensor_type === sensorType.id
            );
            filteredSensors.forEach((sensor) => {
              sensor.pin_type = sensorType.id;
            });
            pins = [...pins, ...filteredSensors];
          }
        });
      }

      // Process Intersections if DISPLAY_MAP_INTERSECT is active
      if (DISPLAY_MAP_INTERSECT && DATA_MAP_INTERSECT) {
        isDataSelected = true; // Mark as selected if intersections are displayed

        let pins_intersect = DATA_MAP_INTERSECT.map((intersect) => {
          intersect.pin_type =
            intersect.trafficlight > 0 ? "intersect_trafficlight" : "intersect";
          intersect.color = "#999";

          let status = ENUM.condition_intersect.find(
            (legend) => intersect.status === legend.status
          );
          if (status) {
            intersect.color = status.color;
          }
          return intersect;
        });

        // Filter pins by traffic light status if specified
        if (DISPLAY_MAP_INTERSECT_TRAFFIC_LIGHT !== "") {
          const status = parseInt(DISPLAY_MAP_INTERSECT_TRAFFIC_LIGHT);
          pins_intersect = pins_intersect.filter(
            (intersect) => intersect.trafficlight === status
          );
        }

        pins = [...pins, ...pins_intersect];
      }

      // Process other data sets (Industrial, Port, Airport, Bus, Train)
      if (DISPLAY_MAP_INDUSTRIAL && DATA_MAP_INDUSTRIAL) {
        isDataSelected = true;

        let pins_industrial = DATA_MAP_INDUSTRIAL.map((industrial) => {
          industrial.pin_type = "industrialestate";
          return industrial;
        });
        pins = [...pins, ...pins_industrial];
        polygons = [...polygons, ...pins_industrial];
      }

      if (DISPLAY_MAP_PORT && DATA_MAP_PORT) {
        isDataSelected = true;

        let pins_port = DATA_MAP_PORT.map((port) => {
          port.pin_type = "port";
          return port;
        });
        pins = [...pins, ...pins_port];
        polygons = [...polygons, ...pins_port];
      }

      if (DISPLAY_MAP_AIRPORT && DATA_MAP_AIRPORT) {
        isDataSelected = true;

        let pins_airport = DATA_MAP_AIRPORT.map((airport) => {
          airport.pin_type = "airport";
          return airport;
        });
        pins = [...pins, ...pins_airport];
        polygons = [...polygons, ...pins_airport];
      }

      if (DISPLAY_MAP_BUS && DATA_MAP_BUS) {
        isDataSelected = true;

        let pins_bus = DATA_MAP_BUS.map((bus) => {
          bus.pin_type = "bus";
          return bus;
        });
        pins = [...pins, ...pins_bus];
        polygons = [...polygons, ...pins_bus];
      }

      if (DISPLAY_MAP_TRAIN && DATA_MAP_TRAIN) {
        isDataSelected = true;

        let pins_train = DATA_MAP_TRAIN.map((train) => {
          train.pin_type = "train";
          return train;
        });
        pins = [...pins, ...pins_train];
      }

      // Update the state with pins and polygons
      SET_PIN_MAP(pins);
      SET_POLYGON_MAP([]);

      // Set polygons after a slight delay to handle async rendering
      setTimeout(() => {
        SET_POLYGON_MAP(polygons);
      }, 100);
    }

    // Cleanup function to reset pins and polygons when leaving the page
    // return () => {
    //   if (isDataSelected) {
    //     // Only reload the page if data was selected
    //     window.location.reload();
    //   } else {
    //     // Clear pins and polygons when leaving the page without reloading
    //     SET_PIN_MAP([]);
    //     SET_POLYGON_MAP([]);
    //   }
    // };
  }, [
    DATA_TRAFFIC,
    DATA_PLACE,
    OPTIONS_SENSOR_TYPE, // Trigger re-render when checkbox selections change
    DATA_SENSOR,
    DISPLAY_MAP_INTERSECT,
    DATA_MAP_INTERSECT,
    DISPLAY_MAP_INTERSECT_TRAFFIC_LIGHT,
    DISPLAY_MAP_INDUSTRIAL,
    DATA_MAP_INDUSTRIAL,
    DISPLAY_MAP_PORT,
    DATA_MAP_PORT,
    DISPLAY_MAP_AIRPORT,
    DATA_MAP_AIRPORT,
    DISPLAY_MAP_BUS,
    DATA_MAP_BUS,
    DISPLAY_MAP_TRAIN,
    DATA_MAP_TRAIN,
  ]);

  return (
    <>
      {loading ? (
        <div
          className="main-content"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Spinner></Spinner>
          <div style={{ color: "#000" }}>กำลังโหลด</div>
        </div>
      ) : (
        <div className="main-content">
          <div className="page-content">
            <InsaneCollapseContent
              title="FILTER"
              type="filter"
              color="#005baf"
              style={{ zIndex: 20 }}
              state={state_filter}
              stateChange={set_state_filter}
            >
              {/*เลือกวันที่*/}
              <div className="filter-content">
                <input
                  type="date"
                  className="form-control page-input input-date-mobile"
                  value={search_date}
                  onChange={(event) => set_search_date(event.target.value)}
                />

                <InsaneDatePicker
                  type="date"
                  className="form-control page-input input-date-web"
                  value={search_date}
                  change={set_search_date}
                />

                {/*เลือกช่วงเวลา*/}
                <select
                  className="form-control page-input"
                  value={search_time}
                  onChange={(event) => set_search_time(event.target.value)}
                >
                  {PEAKOPTION.map((option, index) => {
                    return (
                      <option key={index} value={option.id}>
                        {option.title}
                      </option>
                    );
                  })}
                </select>

                {/*เลือกจังหวัด เก่า*/}
                {/* 
                  <select
                  className="form-control page-input"
                  value={search_province}
                  onChange={(event) => set_search_province(event.target.value)}
                >
                  {PROVINCES.map((option, index) => {
                    return (
                      <option key={index} value={option.id}>
                        {option.title}
                      </option>
                    );
                  })}
                </select>
                  */}

                {/*ส่วนที่3 ใหม่*/}
                <select
                  className="form-control page-input"
                  value={search_province || ""} // ถ้าไม่มีค่า ให้ใช้ค่าเริ่มต้นเป็น ""
                  onChange={(event) => {
                    const selectedProvince = event.target.value;
                    set_search_province(selectedProvince); // อัปเดต state
                    localStorage.setItem("search_province", selectedProvince); // บันทึกค่าใหม่ใน localStorage
                  }}
                >
                  {PROVINCES.map((option, index) => {
                    return (
                      <option key={index} value={option.id}>
                        {option.title}
                      </option>
                    );
                  })}
                </select>

                <div className="checkbox-display-container">
                  <div className="head-label">โครงสร้างพื้นฐานคมนาคม</div>
                  <label className="custom-checkmark checkbox">
                    <input
                      type="checkbox"
                      checked={DISPLAY_MAP_PORT}
                      onChange={(event) =>
                        SET_DISPLAY_MAP_PORT(event.target.checked)
                      }
                    />
                    <span className="checkmark"></span>
                    <img className="icon" src={iconPinPort} />
                    <div className="label">ท่าเรือ</div>
                  </label>

                  <label className="custom-checkmark checkbox">
                    <input
                      type="checkbox"
                      checked={DISPLAY_MAP_INDUSTRIAL}
                      onChange={(event) =>
                        SET_DISPLAY_MAP_INDUSTRIAL(event.target.checked)
                      }
                    />
                    <span className="checkmark"></span>
                    <img className="icon" src={iconPinIndustrial} />
                    <div className="label">นิคมอุตสาหกรรม</div>
                  </label>

                  <label className="custom-checkmark checkbox">
                    <input
                      type="checkbox"
                      checked={DISPLAY_MAP_AIRPORT}
                      onChange={(event) =>
                        SET_DISPLAY_MAP_AIRPORT(event.target.checked)
                      }
                    />
                    <span className="checkmark"></span>
                    <img className="icon" src={iconPinAirport} />
                    <div className="label">ท่าอากาศยาน</div>
                  </label>

                  <label className="custom-checkmark checkbox">
                    <input
                      type="checkbox"
                      checked={DISPLAY_MAP_BUS}
                      onChange={(event) =>
                        SET_DISPLAY_MAP_BUS(event.target.checked)
                      }
                    />
                    <span className="checkmark"></span>
                    <img className="icon" src={iconPinBus} />
                    <div className="label">บขส.</div>
                  </label>

                  <label className="custom-checkmark checkbox">
                    <input
                      type="checkbox"
                      checked={DISPLAY_MAP_TRAIN}
                      onChange={(event) =>
                        SET_DISPLAY_MAP_TRAIN(event.target.checked)
                      }
                    />
                    <span className="checkmark"></span>
                    <img className="icon" src={iconPinTrain} />
                    <div className="label">รถไฟ</div>
                  </label>
                </div>

                <div className="checkbox-display-container">
                  <div className="head-label">แหล่งข้อมูล</div>
                  {OPTIONS_SENSOR_TYPE.map((sendor_type, index) => {
                    return (
                      <label className="custom-checkmark checkbox" key={index}>
                        <input
                          type="checkbox"
                          checked={sendor_type.checked}
                          onChange={(event) => {
                            let new_state = [...OPTIONS_SENSOR_TYPE];
                            new_state[index].checked = event.target.checked;
                            SET_OPTIONS_SENSOR_TYPE(new_state);
                          }}
                        />
                        {/*control icon for selected menu*/}
                        <span className="checkmark"></span>
                        {sendor_type.id === "radar" ? (
                          <img className="icon" src={iconRadar} /> //**  radio show icon left menu */
                        ) : sendor_type.id === "cctv" ? (
                          <img className="icon" src={iconCCTV} />
                        ) : sendor_type.id === "bluetooth" ? (
                          <img className="icon" src={iconBluetooth} />
                        ) : null}
                        <div className="label">{sendor_type.title}</div>{" "}
                        {/** name icon left menu by icon */}
                      </label>
                    );
                  })}
                </div>

                <div className="checkbox-display-container">
                  <div className="head-label">สภาพจราจร</div>

                  <label className="custom-checkmark checkbox">
                    <input
                      type="checkbox"
                      checked={DISPLAY_MAP_LINK}
                      onChange={(event) =>
                        SET_DISPLAY_MAP_LINK(event.target.checked)
                      }
                    />
                    <span className="checkmark"></span>
                    <div className="label">ความเร็วเฉลี่ยบนช่วงถนน</div>
                  </label>

                  <label className="custom-checkmark checkbox">
                    <input
                      type="checkbox"
                      checked={DISPLAY_MAP_INTERSECT}
                      onChange={(event) =>
                        SET_DISPLAY_MAP_INTERSECT(event.target.checked)
                      }
                    />
                    <span className="checkmark"></span>
                    <div className="label">ความล่าช้าที่ทางแยก</div>
                  </label>

                  {DISPLAY_MAP_INTERSECT ? (
                    <div
                      style={{
                        marginLeft: 32,
                        display: "flex",
                        flexDirection: "column",
                        gap: 5,
                      }}
                    >
                      <label className="custom-checkmark checkbox">
                        <input
                          type="checkbox"
                          checked={DISPLAY_MAP_INTERSECT_TRAFFIC_LIGHT === ""}
                          onChange={(event) =>
                            SET_DISPLAY_MAP_INTERSECT_TRAFFIC_LIGHT("")
                          }
                        />
                        <span className="checkmark"></span>
                        <div className="label">ทางแยกทั้งหมด</div>
                      </label>

                      <label className="custom-checkmark checkbox">
                        <input
                          type="checkbox"
                          checked={DISPLAY_MAP_INTERSECT_TRAFFIC_LIGHT === "0"}
                          onChange={(event) =>
                            SET_DISPLAY_MAP_INTERSECT_TRAFFIC_LIGHT("0")
                          }
                        />
                        <span className="checkmark"></span>
                        <img
                          className="icon"
                          width={16}
                          height={16}
                          src={iconPinIntersect}
                          style={{ marginTop: 2 }}
                        />
                        <div className="label">ทางแยกไม่มีสัญญาณไฟจราจร</div>
                      </label>

                      <label className="custom-checkmark checkbox">
                        <input
                          type="checkbox"
                          checked={DISPLAY_MAP_INTERSECT_TRAFFIC_LIGHT === "1"}
                          onChange={(event) =>
                            SET_DISPLAY_MAP_INTERSECT_TRAFFIC_LIGHT("1")
                          }
                        />
                        <span className="checkmark"></span>
                        <img
                          className="icon"
                          width={18}
                          height={18}
                          src={iconPinIntersectLight}
                          style={{ marginTop: 2 }}
                        />
                        <div className="label">ทางแยกมีสัญญาณไฟจราจร</div>
                      </label>
                    </div>
                  ) : null}
                </div>

                <div style={{ flexGrow: 1 }}></div>
                {legendLabel.length > 0 ? (
                  <div
                    className="legend-label-container"
                    style={{ marginBottom: 10 }}
                  >
                    <div className="head-label">
                      <span style={{ flexGrow: 1 }}>
                        ความเร็วเฉลี่ยบนช่วงถนน
                      </span>
                      <HintButton
                        title={"ความเร็วเฉลี่ยบนช่วงถนน"}
                        content={"แนะนำความเร็วเฉลี่ยบนช่วงถนน"}
                      />
                    </div>
                    {legendLabel.map((label, index) => {
                      return (
                        <div className="legend" key={index}>
                          <div
                            className="color"
                            style={{
                              background: label.color ? label.color : "#444",
                              minHeight: label.size ? "unset" : 20,
                              height: label.size ? parseInt(label.size) : 20,
                            }}
                          ></div>
                          <div className="text">{label.title}</div>
                        </div>
                      );
                    })}
                  </div>
                ) : null}

                {legendLabelIntersects.length > 0 ? (
                  (DISPLAY_MAP_INTERSECT) ? (
                    ((DISPLAY_MAP_INTERSECT_TRAFFIC_LIGHT === "") || (DISPLAY_MAP_INTERSECT_TRAFFIC_LIGHT === "0")) ? (
                      <div
                        className="legend-label-container"
                        style={{ marginBottom: 10 }}
                      >
                        <div className="head-label">
                          <span style={{ flexGrow: 1 }}>ความล่าช้าที่ทางแยก<br />ไม่มีสัญญาณไฟจราจร</span>
                          <HintButton
                            title={"ความล่าช้าที่ทางแยกไม่มีสัญญาณไฟจราจร"}
                            content={"แนะนำความล่าช้าที่ทางแยกไม่มีสัญญาณไฟจราจร"}
                          />
                        </div>
                        {legendLabelIntersects.map((label, index) => {
                          return (
                            <div className="legend" key={index}>
                              <div
                                className="color"
                                style={{
                                  background: label.color ? label.color : "#444",
                                  minHeight: label.size ? "unset" : 20,
                                  height: label.size ? parseInt(label.size) : 20,
                                }}
                              ></div>
                              <div className="text">{label.title}</div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (null)
                  ) : (null)
                ) : (null)}

                {legendLabelIntersectsTrafficLight.length > 0 ? (
                  (DISPLAY_MAP_INTERSECT) ? (
                    ((DISPLAY_MAP_INTERSECT_TRAFFIC_LIGHT === "") || (DISPLAY_MAP_INTERSECT_TRAFFIC_LIGHT === "1")) ? (
                      <div
                        className="legend-label-container"
                        style={{ marginBottom: 10 }}
                      >
                        <div className="head-label">
                          <span style={{ flexGrow: 1 }}>ความล่าช้าที่ทางแยก<br />มีสัญญาณไฟจราจร</span>
                          <HintButton
                            title={"ความล่าช้าที่ทางแยกมีสัญญาณไฟจราจร"}
                            content={"แนะนำความล่าช้าที่ทางแยกมีสัญญาณไฟจราจร"}
                          />
                        </div>
                        {legendLabelIntersectsTrafficLight.map((label, index) => {
                          return (
                            <div className="legend" key={index}>
                              <div
                                className="color"
                                style={{
                                  background: label.color ? label.color : "#444",
                                  minHeight: label.size ? "unset" : 20,
                                  height: label.size ? parseInt(label.size) : 20,
                                }}
                              ></div>
                              <div className="text">{label.title}</div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (null)
                  ) : (null)
                ) : (null)}

              </div>
            </InsaneCollapseContent>

            <InsaneCollapseContent
              title="DETAIL"
              type="detail"
              color="#34495E"
              style={{ zIndex: 19 }}
              state={state_detail}
              stateChange={set_state_detail}
            >
              <div className="detail-content">
                <button
                  style={{ color: "#FFF", fontSize: 14, fontWeight: "bolder" }}
                  onClick={() => clearMapForMost5()}
                >
                  Clear Map Highlight
                </button>
                {FILTER_TRAFFIC_OPTION.map((filter_traffic, index) => {
                  return (
                    <div className="content-bar" key={index}>
                      <div
                        className="title"
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        {filter_traffic.title}
                      </div>

                      <div className="lists">
                        <div className="header">
                          <div className="t">
                            <button
                              className="bt-download"
                              onClick={() => onDownloadMost5(filter_traffic.id)}
                            >
                              ดาวน์โหลด
                            </button>
                          </div>
                          <div className="g">
                            <div className="label">{filter_traffic.unit}</div>
                          </div>
                        </div>

                        {filter_traffic.lists.map((list_most5, jndex) => {
                          return (
                            <div
                              className="list"
                              key={jndex}
                              onClick={() => panMapForMost5(list_most5)}
                            >
                              <div className="t">{list_most5.lebel}</div>
                              <div className="g">
                                <InsaneRatioBar
                                  total={{ label: "", size: list_most5.total }}
                                  value={{
                                    label: parseFloat(list_most5.value).toFixed(
                                      1
                                    ),
                                    size: list_most5.percent_value,
                                  }}
                                />
                                <div className="hour">
                                  ช่วงเวลา <br />
                                  {list_most5.hour}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </InsaneCollapseContent>

            <div className="content">
              <div className="display-control-contianer">
                <div
                  className="contianer"
                  onClick={() =>
                    state_filter
                      ? set_state_filter(false)
                      : set_state_filter(true)
                  }
                >
                  <div className="title">{search_date}</div>
                  <div className="title">
                    {getDisplayFilter("peak", "title", "id", search_time)}
                  </div>
                  <div className="title">
                    {getDisplayFilter(
                      "filter_province",
                      "title",
                      "id",
                      search_province
                    )}
                  </div>
                </div>

                <div className="contianer control-mobile">
                  <button
                    onClick={() =>
                      state_detail
                        ? set_state_detail(false)
                        : set_state_detail(true)
                    }
                  >
                    Detail
                  </button>
                </div>
              </div>

              {
                // <div ref={pinMapRef}>
                <OpenStreetMap
                  pinmap={PIN_MAP}
                  polygon={POLYGON_MAP}
                  // polyline={POLYLINE_MAP} //
                  traffic={TRAFFIC_MAP}
                  circle={CIRCLE_MAP}
                  fitBoundsRef={fitBounds_MAP_ref}
                  center={mapSettingDefault.center}
                  zoom={mapSettingDefault.zoom}
                  time={search_time}
                  source={SOURCE}
                />
                //  </div>
              }

              {/* {
                DATA_TRAFFIC_LOADING_PROGRESS ? (
                  <InsaneLoadingProgress
                    show={DATA_TRAFFIC_LOADING_PROGRESS.state}
                    title={DATA_TRAFFIC_LOADING_PROGRESS.title}
                    value={DATA_TRAFFIC_LOADING_PROGRESS.value}
                    total={DATA_TRAFFIC_LOADING_PROGRESS.total}
                  />
                ) : (null)
              } */}
            </div>

            <div className="content-mobile"></div>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
