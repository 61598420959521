import "./styles.css";
import React, { Fragment } from "react";
import Spinner from "../../component/spinner";
import TAB_1 from "./tab1";
// import TAB_2 from "./tab2";
// import TAB_3 from "./tab3";

import TAB_2_3 from "./tab_2_3";

// import IconDownload from "../../assets/icons/download-icon.png";
// import { HttpRequest, HttpRequestForCSV } from "../../service/HttpRequest";


function App() {

  const [loading, setLoading] = React.useState(false);

  const TABS = [
    { id: "23", title: "ช่วงถนนและแยกที่ติดขัดประจำ", element: <TAB_2_3 /> },
    { id: "1", title: "การเดินทางขนส่งสินค้าในพื้นที่ EEC", element: <TAB_1 /> }
  ]

  const [TABS_SELECTED, SET_TABS_SELECTED] = React.useState("23");
  const [TABS_SELECTED_STATE, SET_TABS_SELECTED_STATE] = React.useState(false);

  function toggleTabSelectOption() {
    if (TABS_SELECTED_STATE) {
      SET_TABS_SELECTED_STATE(false);
    } else {
      SET_TABS_SELECTED_STATE(true);
    }
  }

  function getTitleTab(id) {
    let result = TABS.filter((e) => e.id === id);
    if (result.length > 0) {
      return result[0].title;
    } else {
      return "";
    }
  }

  return (
    <>
      {loading ? (
        <div
          className="main-content"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Spinner></Spinner>
          <div style={{ color: "#000" }}>กำลังโหลด</div>
        </div>
      ) : (
        <div className="main-content">

          <div className="page-content">
            <div className="content dashboard-page">
              <div className="tab-container mobile"
                style={{ justifyContent: "center" }}
                onClick={() => toggleTabSelectOption()}>
                {
                  getTitleTab(TABS_SELECTED)
                }
                <div className="toggle-icon">
                  {
                    TABS_SELECTED_STATE ? "▲" : "▼"
                  }
                </div>
              </div>
              <div className={"tab-container collapse-mobile " + (TABS_SELECTED_STATE ? "active" : "")} style={{ display: "flex" }} >
                {
                  TABS.map((tab, index) => (

                    <div
                      key={index}
                      className={"tab-button " + (TABS_SELECTED === tab.id ? "active" : "")}
                      onClick={() => {
                        SET_TABS_SELECTED(tab.id);
                        SET_TABS_SELECTED_STATE(false);
                      }}>
                      {tab.title}
                    </div>

                  ))
                }

              </div>
              {
                TABS.map((tab, index) => (
                  <Fragment key={index}>
                    {(TABS_SELECTED === tab.id) ? (tab.element) : (null)}
                  </Fragment>
                ))
              }
            </div>
            <div className="content-mobile"></div>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
