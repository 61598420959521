import React from 'react';
import './styles.css';

const ConfirmDialog = ({ open, onClose, onConfirm, title, message }) => {
    if (!open) return null;

    return (
        <div className="dialog-overlay">
            <div className="dialog-box">
                <h3 className="dialog-title">{title}</h3>
                <p className="dialog-message">{message}</p>
                <div className="dialog-actions">
                    <button onClick={onClose} className="dialog-button cancel-button">
                        Cancel
                    </button>
                    <button onClick={onConfirm} className="dialog-button confirm-button">
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmDialog;
